<template>
  <div class="w-full 2xl:p-4">
    <div class="grid grid-cols-4 gap-4 mb-4">
      <div class="col-span-1">
        <UserStats />
      </div>
      <div class="col-span-3">
        <AccountsChart :startDate="startDate" :endDate="endDate" />
      </div>
    </div>

    <div class="my-4 w-full rounded-lg p-6 bg-white border border-gray-200">
      <OrganizationsTable />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import OrganizationsTable from '../tech_stack_organizations_table.vue'
import UserStats from './user_stats.vue'
import AccountsChart from './accounts_chart.vue'

onMounted(() => {
  const loadingSpinner = document.getElementById('loading-spinner')
  if (loadingSpinner) {
    loadingSpinner.style.display = 'none'
  }
})
</script>