<template>
  <div class="">
    <div class="">
      <span> Search Customers</span>
      <input
        v-model="search"
        type="text"
        class="form-control mb-5"
        style="width: auto"
        autofocus>

      <span>
        <input
          v-model="showDeleted"
          type="checkbox">
        Hide deleted organizations
      </span>

      <VueSlimTables
        ref="table"
        :source="tableSource"
        :columns="columns"
        class="table w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 "
        :per-page="50">
        <template #cell:customer="{ row }">
        <div class="flex flex-col space-y-2">
          <div class="font-semibold text-lg">
            <a :href="`/organizations/${row.id}`">{{ row.name || 'N/A' }}</a>
          </div>
          <div class="text-sm text-gray-600">{{ row.owner }}</div>
          <div class="flex items-center space-x-2">
            <span class="font-medium text-sm">Plan:</span>
            <span :class="getPlanBadgeClass(row.plan)">{{ row.plan || 'Undefined' }}</span>
          </div>
          <div class="text-sm">
            <span class="font-medium">Customer for:</span> {{ calculateCustomerDuration(row.createdAt) }}
          </div>
        </div>
      </template>

       <template #cell:statuses="{ row }">
      <div class="flex flex-col space-y-2">
        <div>
          <span class="text-xs font-medium mr-2">Onboarding:</span>
          <span :class="getOnboardingBadgeClass(row.onboardingState)">
            {{ formatOnboardingState(row.onboardingState) }}
          </span>
        </div>
        <div>
          <span class="text-xs font-medium mr-2">Provisioning:</span>
          <span :class="getProvisioningBadgeClass(row)">
            {{ formatProvisioningStatus(row) }}
          </span>
        </div>
      </div>
    </template>

        <template #cell:moments="{ row }">
          <div class="flex flex-col space-y-2 text-xs">
            <div class="flex justify-between">
              <div v-for="(count, period) in row.momentCounts" :key="period" class="text-center px-1">
                <div class="font-bold">{{ formatPeriod(period) }}</div>
                <div>{{ count }}</div>
              </div>
            </div>
            <hr class="border-t border-gray-300 my-1">
            <div :class="getLastMomentClass(row.timeSinceLastMoment)">
              Last: {{ formatTimeSinceLastMoment(row.timeSinceLastMoment) }}
            </div>
          </div>
        </template>



        <template #cell:features="{ row }">
          <div class="flex flex-col space-y-2">
            <label class="inline-flex items-center">
              <input type="checkbox"
                     :checked="row.featureLocations"
                     @change="updateFeature(row.id, 'feature_locations', $event.target.checked)">
              <span class="ml-2">Location Management</span>
            </label>
            <label class="inline-flex items-center">
              <input type="checkbox"
                     :checked="row.featureChat"
                     @change="updateFeature(row.id, 'feature_chat', $event.target.checked)">
              <span class="ml-2">Chat Management</span>
            </label>
            <label class="inline-flex items-center">
              <input type="checkbox"
                     :checked="row.featureLeadManagement"
                     @change="updateFeature(row.id, 'feature_lead_management', $event.target.checked)">
              <span class="ml-2">Lead Management</span>
            </label>
            <label class="inline-flex items-center">
              <input type="checkbox"
                     :checked="row.featureReputationManagement"
                     @change="updateFeature(row.id, 'feature_reputation_management', $event.target.checked)">
              <span class="ml-2">Reputation Management</span>
            </label>
          </div>
        </template>

        <template #cell:actions="{ row }">
          <div class="flex space-x-2">
            <a :href="`/organizations/${row.id}`" title="View">
              <i class="fas fa-eye"></i>
            </a>
            <a :href="`/organizations/${row.id}/notes`" title="Notes">
              <i class="fas fa-sticky-note"></i>
            </a>

          </div>
        </template>
      </VueSlimTables>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import VueSlimTables from 'vue-slim-tables'
import axios from '../init/axios'
import 'vue-slim-tables/stylesheets'
import { runSwal } from '../common/delete_with_swal'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'

export default {
  name: 'TechStackOrganizationsTable',
  components: { VueSlimTables },
  props: { clientId: String },
  data() {
    return {
      search: '',
      showDeleted: true,
      perPage: 25
    }
  },
  watch: {
    search(_val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 500)
    },
    showDeleted(_val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 500)
    }
  },
  created() {
    this.columns = [
      { title: 'Customer', key: 'customer' },
      { title: 'Statuses', key: 'statuses' },
      { title: 'Moments', key: 'moments' },
      { title: 'Features', key: 'features' },
      { title: 'Actions', key: 'actions' }
    ]
  },
  methods: {
    reload() {
      this.$refs.table.fetchData(this.$refs.table.page)
    },

    onDelete(id) {
      const params = {
        content: 'This will hide the organization. You will be able to restore it later.'
      }

      runSwal(() => {
        axios.delete(`/organizations/${id}`)
        window.location.reload()
      }, params)
    },

    updateFeature(organizationId, feature, value) {
      axios.patch(`/ajax/organizations/${organizationId}/update_feature`, {
        feature: feature,
        value: value
      })
      .then(response => {
        if (response.data.success) {
          mdtoast.success('Updated feature', { duration: 10000 })
          const index = this.$refs.table.items.findIndex(item => item.id === organizationId)
          if (index !== -1) {
            this.$set(this.$refs.table.items[index], feature, value)
          }
        } else {
          console.error('Failed to update feature:', response.data.message)
        }
      })
      .catch(error => {
        console.error('Error updating feature:', error)
      })
    },

    onRestore(id) {
      const params = {
        content: 'You will restore the organization and all its users will become active.'
      }

      runSwal(() => {
        axios.put(`/organizations/${id}/restore`)
        window.location.reload()
      }, params)
    },

    getProvisioningBadgeClass(row) {
      const baseClasses = 'px-2 inline-flex text-xs leading-5 font-semibold rounded'
      if (this.isProvisionStatusNA(row)) {
        return `${baseClasses} bg-gray-100 text-gray-800`
      }
      switch (row.provisionStatus) {
        case 'failed':
          return `${baseClasses} bg-red-100 text-red-800`
        case 'in_progress':
          return `${baseClasses} bg-yellow-100 text-yellow-800`
        case 'provisioned':
          return `${baseClasses} bg-green-100 text-green-800`
        default:
          return `${baseClasses} bg-gray-100 text-gray-800`
      }
    },

    formatProvisioningStatus(row) {
      if (this.isProvisionStatusNA(row)) {
        return 'N/A'
      }
      switch (row.provisionStatus) {
        case 'failed':
          return 'Failed'
        case 'in_progress':
          return 'In Progress'
        case 'provisioned':
          return 'OK'
        default:
          return 'Unknown'
      }
    },

    isProvisionStatusNA(row) {
      const status = row.provisionStatus
      return status === 'no_billing_plan' || status === 'pending_welcome_form' || status === 'nothing_to_do'
    },

    tableSource(params) {
      return axios.get(
        '/ajax/organizations/tech_stack_customers',
        {
          params: { ...params, query: this.search, per_page: this.perPage, show_deleted: this.showDeleted },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      ).then((res) => res.data)
    },

    timeSinceCreated(createdAt) {
      return new Date(createdAt).toLocaleDateString()
    },
     getPlanBadgeClass(plan) {
      if (plan == undefined) {
        return 'text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'
      }
      const baseClasses = 'text-xs font-medium me-2 px-2.5 py-0.5 rounded'
      switch (plan.toLowerCase()) {
        case 'gpp':
          return `bg-blue-100 text-blue-800 ${baseClasses} dark:bg-blue-900 dark:text-blue-300`
        case 'sitesense':
          return `bg-green-100 text-green-800 ${baseClasses} dark:bg-green-900 dark:text-green-300`
        case 'bundle':
          return `bg-purple-100 text-purple-800 ${baseClasses} dark:bg-purple-900 dark:text-purple-300`
        default:
          return `bg-gray-100 text-gray-800 ${baseClasses} dark:bg-gray-700 dark:text-gray-300`
      }
    },

    calculateCustomerDuration(createdAt) {
      const now = new Date()
      const created = new Date(createdAt)
      const diffTime = Math.abs(now - created)
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

      const years = Math.floor(diffDays / 365)
      const months = Math.floor((diffDays % 365) / 30)
      const days = diffDays % 30

      let duration = ''
      if (years > 0) {
        duration += `${years} year${years > 1 ? 's' : ''} `
      }
      if (months > 0) {
        duration += `${months} month${months > 1 ? 's' : ''} `
      }
      if (days > 0 || duration === '') {
        duration += `${days} day${days !== 1 ? 's' : ''}`
      }

      return duration.trim()
    },
    getOnboardingBadgeClass(state) {
      const baseClasses = 'px-2 inline-flex text-xs leading-5 font-semibold rounded'
      switch (state) {
        case 'pending_contact':
          return `${baseClasses} bg-red-100 text-red-800`
        case 'contacted':
          return `${baseClasses} bg-blue-100 text-blue-800`
        case 'onboarded':
          return `${baseClasses} bg-green-100 text-green-800`
        default:
          return `${baseClasses} bg-gray-100 text-gray-800`
      }
    },

    formatPeriod(period) {
      const periods = {
        last_30_days: '30d',
        last_90_days: '90d',
        last_180_days: '180d',
        last_12_months: '12m'
      }
      return periods[period] || period
    },

    formatTimeSinceLastMoment(seconds) {
      if (seconds === null) return 'Never'
      const days = Math.floor(seconds / (24 * 60 * 60))
      if (days < 30) return `${days}d ago`
      const months = Math.floor(days / 30)
      return `${months}m ago`
    },

    getLastMomentClass(seconds) {
      if (seconds === null) return 'text-red-600 font-bold animate-pulse'
      const days = Math.floor(seconds / (24 * 60 * 60))
      if (days < 7) return 'text-green-600'
      if (days < 30) return 'text-yellow-600'
      return 'text-red-600'
    },

    formatOnboardingState(state) {
      return state.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    }
  }
}
</script>

<style scoped>
@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>